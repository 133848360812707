import React from 'react'
import '../styles/global.scss'

export default function CaseStudy({
    icon = false,
    title,
    link,
    buttonText,
    bgImage,
    block = 'case-study',
    modifier = 'default',
}) {
    let backgroundImageString = ''

    if (typeof bgImage === 'string') {
        backgroundImageString = bgImage
    } else {
        backgroundImageString = bgImage.childImageSharp.gatsbyImageData.src
    }

    let renderIcon
    let iconOclass // ironic

    if (icon) {
        renderIcon = (
            <img src={icon} alt="Case Study" className={`${block}__icon`} />
        )
        iconOclass = `${block}--icon`
    }

    return (
        <a
            style={{ backgroundImage: `url(${backgroundImageString})` }}
            className={`${block} ${block}--${modifier} ${iconOclass}`}
            href={link}
            target="_blank"
            rel="noreferrer"
        >
            {renderIcon}
            <div className={block + '__title'}>{title}</div>
            <div className={block + '__button button button-cta'}>
                {buttonText}
            </div>
        </a>
    )
}
