import React, { useState } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Layout from '../../components/Layout'
import CaseStudy from '../../components/CaseStudy'
import ImgBox from '../../components/ImgBox'
import HouseCheckSVG from '../../images/openhouseblitz/house-check.svg'
import HouseBookSVG from '../../images/openhouseblitz/house-book.svg'
import CaseStudySVG from '../../images/openhouseblitz/case-study-icon.svg'
import FromTheBlog from '../../components/FromTheBlog'

function OpenHouseBlitz({ data }) {
    const [showFloatingToc, setShowFloatingToc] = useState(false)

    return (
        <Layout
            title={'Guide to Mega Open House Blitzes | HomeSpotter'}
            description=""
            siteContainerClassName="openhouseblitz__wrapper"
            bodyClass="openhouseblitz"
        >
            <div className="openhouseblitz__content">
                {/*Header*/}
                <div className="openhouseblitz__header">
                    <div className="openhouseblitz__content-wrap">
                        <GatsbyImage
                            image={data.hero.childImageSharp.gatsbyImageData}
                            className="hero-illustration"
                        />

                        <h1>Guide to Mega Open House Blitzes</h1>

                        <p className="openhouseblitz__sub-title">
                            An ideal mega open house blitz looks something like
                            this
                        </p>
                        <div className="openhouseblitz__header-copy">
                            <p className="openhouseblitz__title-description">
                                Over the course of a weekend, nearly 500 broker
                                listings are held open — including one that
                                boasts more than 100 attendees. Thousands of
                                directional signs are staked across your market
                                area. Facebook ads targeting a local audience
                                garner tens of thousands of impressions. Eager
                                agents fire up Instagram to show off the food
                                truck they rented for the occasion.
                            </p>

                            {showFloatingToc ? (
                                <React.Fragment>
                                    <div
                                        className="openhouseblitz__toc-toggle open"
                                        onClick={() =>
                                            setShowFloatingToc(false)
                                        }
                                        role="button"
                                        tabIndex={0}
                                    />
                                    <div className="openhouseblitz__toc-floating">
                                        <h6>Table of Contents</h6>
                                        <ul>
                                            <li>
                                                <a href="#chapter-intro">
                                                    Intro
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#chapter-1">
                                                    What is an Open House Blitz?
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#chapter-2">
                                                    Planning a successful Open
                                                    House Blitz
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#chapter-3">
                                                    Marketing an Open House
                                                    Blitz
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#chapter-4">
                                                    Empowering your agents to
                                                    succeed at their open houses
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#chapter-5">
                                                    Setting goals and measuring
                                                    ROI
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </React.Fragment>
                            ) : (
                                <div
                                    className="openhouseblitz__toc-toggle"
                                    onClick={() => setShowFloatingToc(true)}
                                    role="button"
                                    tabIndex={0}
                                />
                            )}

                            <div className="openhouseblitz__table-of-contents">
                                <h2>Table of Contents</h2>

                                <ul>
                                    <li>
                                        <a href="#chapter-1">
                                            What is an Open House Blitz?
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#chapter-2">
                                            Planning a successful Open House
                                            Blitz
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#chapter-3">
                                            Marketing an Open House Blitz
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#chapter-4">
                                            Empowering your agents to succeed at
                                            their open houses
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#chapter-5">
                                            Setting goals and measuring ROI
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                {/*Intro*/}
                <div className="openhouseblitz__intro">
                    <div className="openhouseblitz__content-wrap">
                        <div className="openhouseblitz__content-block">
                            <p className="openhouseblitz__large-paragraph">
                                And after the city-wide events conclude, data
                                streams in. It shows that most attendees are
                                serious, early-stage buyers in need of an agent.
                                12% of them indicate they’d like pre-approval
                                help. The contact information captured at each
                                open house is auto-synced to agent CRMs and
                                agents begin their automated and one-on-one
                                follow-up plans. The agent or broker can also
                                leverage the contact information of each open
                                house attendee to generate smart retargeting
                                campaigns that promote the brokerage or specific
                                listings. The ultimate goal is to keep the
                                listing and listing agent top of mind in the
                                days following the open house.
                            </p>

                            <p className="openhouseblitz__large-paragraph">
                                Sound like your real estate fantasy? Nope, it’s
                                just a really successful “mega open house
                                blitz.” These are just some of the results we’ve
                                heard from clients whose brokerages or brands
                                host large-scale open house events.
                            </p>

                            <p className="openhouseblitz__large-paragraph">
                                Getting there requires a lot of coordination and
                                communication. But when there’s a smart strategy
                                driving these events, it’s possible to achieve
                                high agent participation, complete client
                                satisfaction, an increase in motivated
                                buyer-side leads and higher brand recognition
                                across your market. In short, open houses — yes,
                                open houses — can give you lift across nearly
                                all facets of your business.
                            </p>

                            <p className="openhouseblitz__large-paragraph">
                                So, how do you execute a pitch-perfect mega open
                                house blitz? <br />
                                Let’s dive right in.
                            </p>
                        </div>
                    </div>
                </div>

                {/*Chapter 1*/}
                <div className="openhouseblitz__chapter" id="chapter-1">
                    <div className="openhouseblitz__content-wrap">
                        <div className="content-container">
                            <h2>Chapter 1</h2>
                            <h3>What is a mega open house blitz?</h3>
                            <GatsbyImage
                                image={
                                    data.ch1_hero.childImageSharp
                                        .gatsbyImageData
                                }
                                className="hero-illustration"
                            />
                            <p>
                                A mega open house blitz is a coordinated open
                                house event, where dozens (or even hundreds) of
                                agents hold their listings open during the same
                                time frame. Typically, the brokerage’s marketing
                                department plans and executes the event with the
                                help of office staff. Whether the event is
                                hosted by one office, a boutique brokerage or a
                                local franchise for a larger brand, the goal of
                                the open house event remains the same — to
                                increase buyer lead generation for agents and as
                                a result, for the broker.
                            </p>

                            <p>
                                Mega open house blitzes operate on economy of
                                scale. While they require upfront work from the
                                broker or brand, a coordinated open house event
                                can help agents organize and promote their
                                events more effectively. The result is a
                                cross-town event that benefits buyers, sellers,
                                agents and the brands they represent.
                            </p>

                            <ImgBox
                                imgSrc={
                                    data.edinaopenhouse.childImageSharp
                                        .gatsbyImageData
                                }
                            />

                            <h4>
                                How consumers benefit from an open house blitz
                            </h4>

                            <p>
                                According to the National Association of
                                REALTORS®, 90% of homebuyers
                                <span className="footnote-number">1</span> say
                                that open houses are a valuable source of
                                information. When offered an easy-to-plan open
                                house tour experience, buyers can get a quick
                                and more complete view of the current market and
                                how they fit into it.
                            </p>

                            <p>
                                Additionally, most buyers report that while the
                                home shopping experience is exciting, it can
                                also be quite time-consuming. Open house blitzes
                                allow early-stage buyers to better optimize
                                their time. Rather than waiting outside listings
                                for a private showing to begin, buyers can zig
                                and zag to a series of homes, spending as little
                                or as much time as they want in each property.
                                At the end of their blitz tour, buyers can
                                narrow down their options and make a short list
                                of homes they wish to visit again in the future.
                            </p>

                            <p>
                                On the seller side, the benefits are pretty
                                obvious. By taking part in a large-scale open
                                house event, a listing is more likely to
                                generate online and in-person traffic. This can
                                be equally important for recently-listed homes
                                and homes that have been lagging on the market.
                            </p>

                            <p>
                                For brand-new listings, a mega open house event
                                generates buyer traffic early on, which may help
                                the seller get fast offers or additional private
                                showing requests. These events can also help
                                houses that have been on the market for longer.
                                By hosting a heavily-promoted open house for an
                                older listing, the listing agent can show the
                                seller that the property remains a priority.
                                These open houses can also draw in buyers who
                                may not otherwise look at a listing that’s been
                                stigmatized by a long-time market status.
                            </p>

                            <div className="footnote">
                                1. Source: NAR 2018 Profile of Buyers and
                                Sellers
                            </div>

                            <h4>
                                How brands and brokers benefit from a mega open
                                house blitz
                            </h4>

                            <p className="extra-attention">
                                For brands and brokers, these large-scale open
                                house events boost external brand recognition
                                and internal brand loyalty among agents.
                            </p>

                            <p>
                                Between online ads, Facebook events and a flurry
                                of directional signs all across town, these
                                events offer heightened brand awareness across
                                the local market. They can also motivate buyers
                                to view more broker-listed properties, as many
                                plan to spend an entire afternoon visiting local
                                open houses.
                            </p>

                            <p>
                                Brands often find that agents are more likely to
                                participate in the coordinated open house
                                events, especially if their office or franchise
                                provides marketing support or other turnkey
                                technology tools. By reviewing post-event
                                metrics, brokers can easily track agent and
                                office participation, as well as overall foot
                                traffic.
                            </p>

                            <h4>
                                How participating agents benefit from a mega
                                open house blitz
                            </h4>

                            <p>
                                Agents benefit from an immediate boost in
                                exposure and foot traffic for their listings, as
                                city-wide open house blitzes tend to draw in
                                more attendees than singular events.
                                Additionally, both rookie and veteran agents can
                                find advantages in the mega open house blitz
                                model.
                            </p>

                            <p>
                                Long-term agents who prefer to focus on listings
                                — or who may have more than one listing to
                                support — can ask newer agents to host an open
                                house on their behalf. Less seasoned agents
                                benefit from the robust event promotions and
                                they are typically allowed to “claim” the buyer
                                leads generated throughout the event. Being
                                given the opportunity to meet early-stage buyers
                                can be extremely advantageous for rookie agents,
                                as{' '}
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://www.nar.realtor/research-and-statistics/research-reports/highlights-from-the-profile-of-home-buyers-and-sellers#homebuyers"
                                >
                                    NAR data
                                </a>{' '}
                                shows that 75% of buyers work with the first
                                agent they interview.
                            </p>

                            <h4>
                                The conversion question: Can open house blitzes
                                lead to more immediate business?
                            </h4>

                            <p>
                                Open house attendees can be a critical source of
                                business for agents who methodically follow up
                                with early-stage buyers quickly after the event.
                                But data shows most agents don’t factor these
                                leads into their business plans.
                            </p>

                            <p>
                                According to the NAR, just 5% of buyers say they
                                met their agent at an open house, but our data
                                shows that 71% of open house attendees checking
                                in via Spacio are unaffiliated. The gap is
                                astounding, but it also indicates a big business
                                opportunity for agents, brokers and brands.
                            </p>

                            <p>
                                By investing in a coordinated open house
                                approach, brands can empower agents to make
                                meaningful contact with eager, early-stage
                                buyers who are (often) still looking for an
                                agent.
                            </p>

                            <p>
                                Ready to rev up your open house ROI? See how top
                                agents drive in leads and business by focusing
                                on four promising groups of open house
                                attendees.
                            </p>

                            <FromTheBlog
                                title={
                                    'Four groups (and potential clients) agents are overlooking at open houses'
                                }
                                img={data.fourGroupsOpenHouses}
                                link={
                                    'https://blog.homespotter.com/2020/03/02/four-groups-and-potential-clients-agents-are-overlooking-at-open-houses/'
                                }
                            />
                        </div>
                    </div>
                </div>

                {/*Chapter 2*/}
                <div className="openhouseblitz__chapter" id="chapter-2">
                    <div className="openhouseblitz__content-wrap">
                        <div className="content-container">
                            <h2>Chapter 2</h2>
                            <h3>Planning a successful mega open house blitz</h3>
                            <GatsbyImage
                                image={
                                    data.ch2_hero.childImageSharp
                                        .gatsbyImageData
                                }
                                className="hero-illustration"
                            />

                            <p>
                                First things first: Who’s in charge? These open
                                house events can require a lot of coordination,
                                but you also don’t want too many cooks in the
                                kitchen. Consider forming a small committee that
                                represents stakeholders across the brand,
                                including:
                            </p>

                            <ul className="content-bullets">
                                <li>Executives</li>
                                <li>Marketing and IT Staff</li>
                                <li>Sales managers or brokers</li>
                                <li>Office administrative staff</li>
                                <li>High-performing agent(s)</li>
                                <li>Rookie agent(s)</li>
                            </ul>

                            <h4>
                                How to determine the best time of year for your
                                open house events
                            </h4>

                            <p>
                                From there, the next step is to schedule a full
                                year of open house blitz dates so that all
                                stakeholders — agents and their support staff,
                                corporate employees and vendors — can prepare
                                accordingly.
                            </p>

                            <p>
                                When is the best time of year for these events?
                                It really depends on your market and typical
                                market cycle. Many brands kick off the spring
                                with a mega open house blitz in February; they
                                may run 1-2 open house blitzes per month through
                                the end of their busy season in the fall.
                            </p>

                            <p>
                                Of course, your market may behave a little
                                differently, so consider inventory and monthly
                                sales volume when determining your schedule.
                            </p>

                            <div className="openhouseblitz__pull-quote">
                                <p>
                                    Right now, we are running mega open houses
                                    in March, April, May, June and then in
                                    September and October. So we host six per
                                    year, focusing on the spring and fall
                                    because that’s when our listings typically
                                    see the most foot traffic.
                                </p>
                                <div className="quote-author">
                                    Katie Redican
                                </div>
                                <div className="quote-company">
                                    Vice President of Operations - Berkshire
                                    Hathaway HomeServices New England
                                </div>
                            </div>

                            <div className="openhouseblitz__pull-quote">
                                <p>
                                    We have one open house celebration weekend a
                                    month, year-round. In the past we ran them
                                    every first weekend of the month, but we’ve
                                    run into times where that overlapped with a
                                    major [Kansas City] Chiefs game. So of
                                    course that affects attendance. We’re now a
                                    little more flexible on the weekend we
                                    choose each month, because we want to be
                                    cognizant of what's going on in the market.
                                </p>
                                <div className="quote-author">
                                    Marissa Easter
                                </div>
                                <div className="quote-company">
                                    Director of Marketing - ReeceNichols
                                </div>
                            </div>

                            <p>
                                Scheduling the dates in advance isn’t only
                                helpful for those involved in planning the event
                                logistics. When agents have the events on their
                                radar, they can also use them as leverage in
                                their listing presentations with potential
                                sellers.
                            </p>

                            <h4>
                                Should brands hold all their open houses at the
                                exact same time?
                            </h4>

                            <p>
                                There are no hard-and-fast rules that dictate
                                ideal timing for a mega open house blitz, though
                                most companies choose to host them on weekends.
                                Some brokers set a strict time range so that
                                participants can plan a concurrent tour of
                                listings around town; others allow their agents
                                to schedule opens across a few days.
                            </p>

                            <div className="openhouseblitz__pull-quote">
                                <p>
                                    During our open house weekend events, we
                                    promote all open houses hosted between
                                    Thursday and Sunday of that week. Some
                                    agents choose to host twilight open houses
                                    on Thursday evenings, or they may host one
                                    on a Friday afternoon so buyers can visit on
                                    their way home from work. We keep it
                                    flexible, so agents and their clients can
                                    pick the times and formats that work best
                                    for them.
                                </p>
                                <div className="quote-author">
                                    Katie Redican
                                </div>
                                <div className="quote-company">
                                    Vice President of Operations - Berkshire
                                    Hathaway HomeServices New England
                                </div>
                            </div>

                            <div className="openhouseblitz__pull-quote">
                                <p>
                                    Our MEGA open house events are on Sunday
                                    afternoons. Our MEGA marketing promotion
                                    advertises the events using Zillow Ads,
                                    HomeSpotter Boost social & digital ads, WAZE
                                    ads, digital billboards and lots of local
                                    neighborhood advertising for our 400+
                                    listings that will be held open. Visitors
                                    can easily use Waze to map out their visits
                                    the day of the event.
                                </p>
                                <div className="quote-author">Tony Floyd</div>
                                <div className="quote-company">
                                    Chief Innovation Officer - Berkshire
                                    Hathaway HomeServices Georgia Properties
                                </div>
                            </div>

                            <h4>
                                Start planning your open house promotions early
                            </h4>

                            <p>
                                Meet with the committee early in the year to
                                determine what marketing support the larger
                                brand will provide for the mega open house
                                blitzes.
                            </p>

                            <div className="right-column-list-with-heading">
                                <p>
                                    Common company-run marketing efforts can
                                    include
                                </p>
                                <ul>
                                    <li>
                                        Social media ads on Facebook, Instagram
                                        and Nextdoor
                                    </li>
                                    <li>Banner ads on popular websites</li>
                                    <li>Digital and traditional billboards</li>
                                    <li>Online or print newspaper ads</li>
                                    <li>
                                        Branded emails to send from company CRM
                                    </li>
                                    <li>
                                        Press releases and customized press
                                        pitches
                                    </li>
                                    <li>
                                        One landing page or{' '}
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://www.facebook.com/events/cutler-real-estate/open-house-blitz/213579892554426"
                                        >
                                            Facebook event
                                        </a>
                                        &nbsp;advertising all participating open
                                        houses
                                    </li>
                                </ul>
                            </div>

                            <p>
                                And if you’ll be running online or social media
                                campaigns for your open houses, don’t forget
                                that you need to build a conversion funnel for
                                consumers who click through. Start designing and
                                coding your open house landing page template
                                well before your first event.
                            </p>

                            <FromTheBlog
                                title={
                                    'What to include on an open house landing page'
                                }
                                img={data.ftb_open_house}
                                link={
                                    'https://blog.homespotter.com/2020/01/23/how-to-build-the-perfect-real-estate-open-house-landing-page/'
                                }
                            />

                            <p>
                                Once you’ve finalized your list of approved
                                marketing channels and tactics, use a project
                                management system to organize tasks and
                                deadlines. Be sure that corporate employees —
                                designers, social media specialists and IT
                                professionals — understand that these events are
                                time-sensitive, so there is no leeway on the
                                timing of deliverables.
                            </p>

                            <h4>How to coordinate with open house vendors</h4>

                            <p>
                                Next, you’ll meet with the vendors who will
                                support your brand during each mega open house
                                blitz.{' '}
                            </p>

                            <ul className="icon-title-list">
                                <li className="printers-signage">
                                    <div className="itl-title">
                                        Printers and signage
                                    </div>
                                    <div className="itl-copy">
                                        If you have approved print or sign
                                        vendors, get advance deadlines for each
                                        event and add them to your internal
                                        communications plan. If your agents are
                                        in charge of their own print materials
                                        and directional signage, ask the vendor
                                        if they would provide a discount code
                                        for each blitz weekend.
                                    </div>
                                </li>
                                <li className="billboard-vendors">
                                    <div className="itl-title">
                                        Billboard vendors and newspaper ads
                                    </div>
                                    <div className="itl-copy">
                                        In the case of both billboards and
                                        newspapers, you’ll want to secure
                                        contracts early in the year to ensure
                                        that you receive the best placements.
                                        Ask for their deadlines upfront so you
                                        can add it to the project plan.
                                    </div>
                                </li>
                                <li className="third-party-vendors">
                                    <div className="itl-title">
                                        Third-party digital ad vendors
                                    </div>
                                    <div className="itl-copy">
                                        <p>
                                            If you’ll be using a marketing
                                            company to run ads on popular social
                                            media channels or websites, check in
                                            with them at the beginning of the
                                            year to see what they may need from
                                            you. To minimize your own effort,
                                            ask if they have the technology to
                                            run automated ads that pull directly
                                            from your MLS data. Some products,
                                            like{' '}
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href="/boost"
                                            >
                                                Boost
                                            </a>
                                            , are able to automatically run ads
                                            for all company listings with
                                            upcoming open houses.
                                        </p>

                                        <p>
                                            In the absence of an automated ad
                                            vendor, be sure that you allow for
                                            plenty of time to manually place
                                            open house ads. (
                                            <a href="#how-to-manually-set-up-fb-openhouse-ads-target">
                                                Check out our guide
                                            </a>{' '}
                                            to manually placing open house ads
                                            on Facebook.)
                                        </p>
                                    </div>
                                </li>
                                <li className="open-house-software">
                                    <div className="itl-title">
                                        Open house software
                                    </div>
                                    <div className="itl-copy">
                                        <p>
                                            Many agents still use a printed
                                            sign-in sheet when they host open
                                            houses, and we believe that’s one
                                            reason that open house lead
                                            conversions are so low. If you’re
                                            going through the trouble of
                                            coordinating a mega open house
                                            blitz, you may want to equip your
                                            agents with software that collects
                                            the contact information of attendees
                                            and follows up automatically after
                                            the events conclude.
                                        </p>

                                        <p>
                                            If it’s not in the budget to pay for
                                            an open house software for each
                                            agent, form a partnership with one
                                            approved vendor. Communicate the
                                            partnership as part of your internal
                                            communications plan.
                                        </p>

                                        <p>
                                            Once you have your open house
                                            software set up, take special
                                            consideration to customize the
                                            fields that you require when
                                            attendees sign in. In the
                                            short-term, the contact information
                                            can be leveraged for lead
                                            generation. Over time, these
                                            sign-ins will provide data and
                                            insights that can guide corporate
                                            initiatives
                                        </p>
                                    </div>
                                </li>
                            </ul>

                            <GatsbyImage
                                image={
                                    data.reece_open_house_celebration
                                        .childImageSharp.gatsbyImageData
                                }
                            />

                            <h4>
                                Using incentives to increase agent participation
                                and foot traffic
                            </h4>

                            <p>
                                According to the brokers and brands we spoke to,
                                the key to improving agent participation and
                                open house foot traffic is to offer incentives
                                that benefit agents and buyers alike.
                            </p>

                            <p>
                                One idea is to offer a sweepstakes-style prize
                                for an attendee who signs in at a participating
                                open house. We’ve heard of brands who offer
                                iPads or Apple watches, while BHHS Georgia
                                Properties opts for a prize package directly
                                related to homeownership.
                            </p>

                            <div className="openhouseblitz__pull-quote with-image">
                                <div className="pq-image">
                                    <GatsbyImage
                                        image={
                                            data.mega_alexa.childImageSharp
                                                .gatsbyImageData
                                        }
                                    />
                                </div>

                                <div className="pq-content">
                                    <p>
                                        For each MEGA open house event, we give
                                        away an Alexa Prize Pack to a visitor
                                        who signs in via Spacio. We use the
                                        product visuals in{' '}
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://www.bhhsgeorgia.com/super-sunday-open-house.aspx"
                                        >
                                            our event promotions
                                        </a>
                                        , so the buyer sees that they could
                                        receive an Echo Show, 2 Echo Dots and a
                                        4-pack of cool smart outlets. It’s a
                                        nice package and it helps our agents to
                                        more easily capture the contact info of
                                        potential buyers attending the events.
                                    </p>
                                    <div className="quote-author">
                                        Tony Floyd
                                    </div>
                                    <div className="quote-company">
                                        Chief Innovation Officer - Berkshire
                                        Hathaway HomeServices Georgia Properties
                                    </div>
                                </div>
                            </div>

                            <p>
                                Other brands, like BHHS New England Properties,
                                motivate agent participation through
                                company-paid promotions. In 2019, the company
                                began using Boost to run automated Facebook ads
                                promoting each participating open house.
                            </p>

                            <div className="openhouseblitz__pull-quote">
                                <p>
                                    Our company-paid digital ad open house
                                    campaigns through Boost by Homespotter are
                                    working tremendously. Not only do our agents
                                    love that they can promise and show a live
                                    Facebook ad to their sellers, we are also
                                    benefiting from increased attendance at our
                                    events and seeing immediate results.
                                </p>
                                <div className="quote-author">
                                    Katie Redican
                                </div>
                                <div className="quote-company">
                                    Vice President of Operations - Berkshire
                                    Hathaway HomeServices New England
                                </div>
                            </div>

                            <p>
                                Remember, it’s only an incentive if the
                                recipient fully understands the benefit. So if
                                you do place digital ads on behalf of your
                                agents, be sure to clearly communicate what the
                                ad will look like and how it will function.
                            </p>

                            <p>
                                Technology companies like HomeSpotter can send
                                each agent a live version of their ad for
                                approval and even program the ad to target the
                                home’s seller directly. If you place the ads
                                manually, send a (static) example ad to your
                                agents so they know what the promotion will look
                                like.
                            </p>

                            <h4>
                                Finalizing your internal communications plan
                            </h4>

                            <p>
                                As any corporate or brokerage representative
                                knows, it can be challenging to communicate
                                deadline-based information to a group of
                                extremely busy agents. But to ensure each mega
                                open house blitz is successful, you’ll need to
                                ensure that agents understand the basics of the
                                event and the resources you have available to
                                help them.
                            </p>

                            <p>
                                Most brokers and brands say that they use a
                                variety of communications methods to get the
                                word out about the events, including e-cards,
                                calendar invites, weekly sales meetings,
                                in-office posters and printed best practices.
                            </p>

                            <p>
                                No matter what channels you use, be sure not to
                                overload your agents and administrative staff
                                all at once. Follow this six-week plan to ensure
                                they have all the information they need at the
                                right time.
                            </p>

                            <ul className="icon-title-list">
                                <li className="four-weeks-before">
                                    <div className="itl-title">
                                        Four weeks before
                                    </div>
                                    <div className="itl-copy">
                                        Remind agents and support staff of the
                                        mega open house blitz date(s) and
                                        encourage them to begin using the event
                                        as leverage in upcoming listing
                                        presentations. If not already complete,
                                        add the event to all company calendars
                                        and invite agents and support staff.
                                    </div>
                                </li>
                                <li className="three-weeks-before">
                                    <div className="itl-title">
                                        Three weeks before
                                    </div>
                                    <div className="itl-copy">
                                        Encourage agents to order directional
                                        signs for their upcoming open houses.
                                    </div>
                                </li>
                                <li className="two-weeks-before">
                                    <div className="itl-title">
                                        Two weeks before
                                    </div>
                                    <div className="itl-copy">
                                        Send a list of open house best
                                        practices, including sign-in scripts,
                                        flyer and postcard templates, local
                                        signage guidelines (if available) and
                                        food and drink ideas. In your sales
                                        meeting, have agents trade scripts that
                                        can encourage attendees to sign in.
                                        <br />
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://blog.homespotter.com/2019/08/08/open-house-script/"
                                        >
                                            Scripts to encourage open house
                                            sign-ins
                                        </a>
                                    </div>
                                </li>
                                <li className="one-week-before">
                                    <div className="itl-title">
                                        One week before
                                    </div>
                                    <div className="itl-copy">
                                        Encourage agents to test out their open
                                        house software so they can set it up
                                        easily at the event. If you’re having an
                                        in-person sales meeting, tell your
                                        agents to bring their tablets and show
                                        them how to sign into{' '}
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://spac.io/"
                                        >
                                            Spacio
                                        </a>{' '}
                                        (or your preferred technology). Then,
                                        have them approve an automated attendee
                                        follow-up email.
                                    </div>
                                </li>
                                <li className="week-of">
                                    <div className="itl-title">
                                        Week of the event
                                    </div>
                                    <div className="itl-copy">
                                        Send{' '}
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href="http://bhhsgeorgia.announcemymove.com/announcement.php/?id=94134"
                                        >
                                            one communication
                                        </a>
                                        , if possible, noting these important
                                        details:
                                        <ul>
                                            <li>
                                                The deadline for when agents
                                                must mark their open houses in
                                                your MLS. (We recommend by
                                                Tuesday.)
                                            </li>
                                            <li>
                                                Remind them of all the
                                                company-sponsored promotions
                                                supporting the blitz, including
                                                any social media or online ads
                                                for individual open houses
                                            </li>
                                            <li>
                                                Remind agents to pick up
                                                last-minute signage, finalize
                                                any print marketing materials
                                                and to distribute flyers or
                                                postcards to the neighborhood
                                            </li>
                                            <li>
                                                Offer new visuals for agents and
                                                offices to use on their social
                                                media channels, including a
                                                Facebook cover photo or a square
                                                post for Instagram.
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="one-week-after">
                                    <div className="itl-title">
                                        One week after the event
                                    </div>
                                    <div className="itl-copy">
                                        Celebrate! Review your open house
                                        software data to determine which agent
                                        had the highest foot traffic, and reward
                                        them with a small gift at your weekly
                                        sales meeting. You can also ask agents
                                        (on the honors system) to submit the
                                        number of promising leads they’ve
                                        followed up with in the days following
                                        the event.{' '}
                                    </div>
                                </li>
                            </ul>

                            <p>
                                This may seem like a lot of work, but once your
                                agents see the value of these open house blitz
                                events, the preparation and execution will run a
                                lot more smoothly.
                            </p>

                            <div className="openhouseblitz__pull-quote">
                                <p>
                                    We've been doing [open house celebrations]
                                    for awhile, so our agents are very familiar
                                    with them. It doesn't take a lot of internal
                                    communications work anymore. In our weekly
                                    sales meetings, we just remind agents to
                                    mark their open houses so they can get the
                                    company-paid promotion for their event.
                                </p>
                                <div className="quote-author">
                                    Marissa Easter
                                </div>
                                <div className="quote-company">
                                    Director of Marketing - ReeceNichols
                                </div>
                            </div>

                            <CaseStudy
                                link="https://smartspot.homespotter.com/checklist-open-house-blitz?utm_source=pillar-page"
                                title="Final checklist for brands running an Open House Blitz"
                                buttonText="Download the Checklist"
                                icon={HouseCheckSVG}
                                bgImage={data.ctaChecklist}
                            />
                        </div>
                    </div>
                </div>

                {/*Chapter 3*/}
                <div className="openhouseblitz__chapter" id="chapter-3">
                    <div className="openhouseblitz__content-wrap">
                        <div className="content-container">
                            <h2>Chapter 3</h2>
                            <h3>
                                How to finalize all the marketing for your open
                                house blitz
                            </h3>
                            <GatsbyImage
                                image={
                                    data.ch3_hero.childImageSharp
                                        .gatsbyImageData
                                }
                                className="hero-illustration"
                            />

                            <p>
                                In the weeks leading up to your mega open house
                                blitz, you'll want to finalize the promotions
                                that were greenlit by the steering committee
                                earlier in the year.
                            </p>

                            <p className="extra-attention">Be sure that</p>

                            <ul className="content-bullets">
                                <li>
                                    Digital and print billboard creative has
                                    been approved and placed
                                </li>
                                <li>
                                    Online or print newspaper ads are submitted
                                    on time
                                </li>
                                <li>
                                    Automated emails advertising the open house
                                    have been scheduled in your company CRM
                                </li>
                                <li>Press releases are drafted</li>
                                <li>
                                    Open house landing pages (if applicable) are
                                    set up
                                </li>
                            </ul>

                            <p>
                                Many brands also run social media ads promoting
                                individual open houses on behalf of their
                                agents. Whether you use a third-party vendor or
                                complete the process in-house, you’ll want to
                                start early in the week to ensure the campaign
                                runs smoothly.
                            </p>

                            <h4>
                                Confirm social media ads with your marketing
                                vendor
                            </h4>

                            <p>
                                If your company is paying for third-party social
                                media ads for each participating agent, be sure
                                to ask your vendor exactly what they need from
                                you.
                            </p>

                            <p>
                                If you're using{' '}
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="/boost"
                                >
                                    Boost
                                </a>{' '}
                                by HomeSpotter, you’ll give us the promotion
                                dates for your open house blitz, and we’ll turn
                                on ads for all broker-listed properties showing
                                open houses during that time frame. Ads can run
                                on Facebook, Instagram and across the web. We
                                pull data directly from your MLS, so you’ll feel
                                comfortable that they’re accurate and
                                up-to-date.
                            </p>

                            <p>
                                HomeSpotter will also send an alert email to
                                each participating agent. Agents can view their
                                exact ad and make edits if needed.
                            </p>

                            <p id="how-to-manually-set-up-fb-openhouse-ads-target">
                                Note: If your vendor doesn’t have MLS
                                integration, you’ll want to be in close
                                communication to ensure that you provide them
                                with the exact data they need for each promoted
                                open house. Ask how much advance time they need
                                to manually place the ads and ensure that your
                                agents mark their open houses well in advance of
                                that deadline.
                            </p>

                            <h4>
                                How to manually set up Facebook open house ads
                                for your brand
                            </h4>

                            <p>
                                Even if you don't have a vendor who will place
                                ads on your behalf, we strongly recommend that
                                you consider promoting each of your
                                broker-listed open houses on Facebook. Facebook
                                counts real estate as one of its top ad
                                categories — and they support these ads
                                accordingly.
                            </p>

                            <p>
                                Without the help of an automated ads vendor,
                                you’ll have to manually build individual ads for
                                each open house you wish to promote. This is a
                                tedious process, and we recommend that you do a
                                mini test run in the weeks leading up to your
                                first blitz to ensure it goes smoothly.
                            </p>

                            <p>
                                Here’s how one brokerage, ReeceNichols,
                                describes their manual process:
                            </p>

                            <div className="openhouseblitz__pull-quote">
                                <p>
                                    First, agents submit their listings through
                                    a data feed on our website. Then we export
                                    the Excel file of that data and we clean the
                                    file up so that it only includes the fields
                                    needed for our open house ads — things like
                                    the description, the price, relevant MLS
                                    data, and pictures of each property. From
                                    there, we use Facebook Business Manager to
                                    set up the campaigns, which includes setting
                                    a budget and the targeting parameters for
                                    each ad.
                                </p>
                                <div className="quote-author">
                                    Marissa Easter
                                </div>
                                <div className="quote-company">
                                    Director of Marketing - ReeceNichols
                                </div>
                            </div>

                            <p className="ftb-pre-copy">
                                Not sure how to build and place these manual
                                ads? We wrote up a quick primer for you.
                            </p>

                            <FromTheBlog
                                title={
                                    '  How to build manual ads for each of your\n' +
                                    '                                        brand’s open houses'
                                }
                                img={data.ftb_manual_ads}
                                link={
                                    'https://blog.homespotter.com/2020/01/23/how-to-manually-create-facebook-open-house-ads-for-your-brand/'
                                }
                            />

                            <h4>
                                Why should I consider automated ads for my mega
                                open house blitz?
                            </h4>

                            <p>
                                A primary reason to outsource this task to a
                                vendor with automation capability is that it
                                will save your team a lot of time and headaches.
                                Large-scale ad campaigns for open house events
                                simply work better when they can pull from a
                                dynamic feed, rather than a static .csv Catalog.
                            </p>

                            <div className="right-column-list-with-heading">
                                <p>
                                    Here are some additional benefits to running
                                    automated ads
                                </p>
                                <ul>
                                    <li>
                                        Ads pull in live, accurate data from
                                        your MLS feed, including price, image
                                        and listing descriptions
                                    </li>
                                    <li>
                                        If an agent adds an open house later
                                        than the designated deadline — say, two
                                        or three days before your blitz event —
                                        a campaign would still be generated on
                                        their behalf
                                    </li>
                                    <li>
                                        If an agent cancels their open house the
                                        day before your blitz, the ad would stop
                                        running
                                    </li>
                                    <li>
                                        Agents can be given the opportunity to
                                        view and tweak their ad before it goes
                                        live; ads will auto-publish even if they
                                        aren’t reviewed by the agent
                                    </li>
                                    <li>
                                        You can set up micro-targeting
                                        parameters so that the home seller is
                                        targeted by their own open house ad on
                                        Facebook
                                    </li>
                                </ul>
                            </div>

                            <p>
                                If you set up and run manual ads, be sure you
                                know how to check your Facebook ad stats for
                                each campaign. That way, you can assure agents
                                that their promotion was run, even if they
                                didn’t see it themselves.
                            </p>

                            <div className="openhouseblitz__pull-quote openhouseblitz__pull-quote--no-margin-bottom">
                                <p>
                                    We sometimes get complaints from agents that
                                    say, ‘I don't think my ad ran because I
                                    never saw it.’ We make sure to track
                                    everything we do. So we always have those
                                    metrics to show, yes, here's what it looked
                                    like and here's how many hits it got.
                                </p>
                                <div className="quote-author">
                                    Marissa Easter
                                </div>
                                <div className="quote-company">
                                    Director of Marketing - ReeceNichols
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*Chapter 4*/}
                <div className="openhouseblitz__chapter" id="chapter-4">
                    <div className="openhouseblitz__content-wrap">
                        <div className="content-container">
                            <h2>Chapter 4</h2>
                            <h3>
                                How to empower your agents to succeed at their
                                open houses
                            </h3>
                            <GatsbyImage
                                image={
                                    data.ch4_hero.childImageSharp
                                        .gatsbyImageData
                                }
                                className="hero-illustration"
                            />

                            <p>
                                Company-run promotions are great for getting the
                                word out about the events, and for boosting
                                attendance each month. But the key to building a
                                long-term, successful open house strategy for
                                your brand is to empower your agents to take the
                                events seriously as both a means for generating
                                potential buyer leads and satisfying existing
                                seller clients.
                            </p>
                            <p>
                                Here are ideas and resources you can offer your
                                agents, to ensure they are all-in any time you
                                host a mega open house blitz.
                            </p>

                            <h4>
                                Ideas to help agents drive traffic to their open
                                house events
                            </h4>

                            <p>
                                In your next sales meeting, poll your agents on
                                the best open house promotions they’ve ever run
                                — and then ask why you think they were so
                                successful.
                            </p>

                            <p className="extra-attention">
                                Get them talking about their favorite ways to
                                drive foot traffic, including
                            </p>

                            <ul className="content-bullets">
                                <li>
                                    Directional signs that lead from busy roads
                                    to individual listings
                                </li>
                                <li>
                                    Promising food and drink, including new
                                    options like food trucks or pop-ups
                                </li>
                                <li>
                                    Kid-friendly features like a bouncy house, a
                                    magician or a kids’ band
                                </li>
                                <li>
                                    Adult-friendly events that include wine or
                                    cheese tasting
                                </li>
                                <li>
                                    An all-day grill-out, complete with a pig
                                    roast and a contest for the best BBQ sauce
                                </li>
                            </ul>

                            <p>
                                Remind agents that they don’t have to spend
                                exorbitant amounts of money in order to boost
                                enthusiasm and attendance. Focus first on making
                                the event unique and memorable.
                            </p>

                            <p>
                                And remember, if you can’t afford to host a
                                blow-out event, consider bringing in local
                                sponsors to co-host. Local restaurants, coffee
                                shops and bakeries are often excited to pair up
                                and extend their reach beyond their typical
                                client base. You can also reach out to your
                                title and lending partners, but be sure to
                                follow{' '}
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://www.nar.realtor/ae/manage-your-association/association-policy/following-respa-rules"
                                >
                                    RESPA guidelines
                                </a>{' '}
                                to the letter.
                            </p>

                            <h4>
                                Resources to help your agents follow up with
                                open house leads
                            </h4>

                            <p>
                                You’ll also want to reinforce to your agents the
                                importance of following up with buyer attendees
                                who indicate they are not yet affiliated with an
                                agent.
                            </p>

                            <p>
                                While it will be up to each individual agent to
                                map out their outreach plan, brokers and brands
                                can give them the tools they need to succeed.
                            </p>

                            <p className="extra-attention">Consider</p>

                            <ul className="content-bullets">
                                <li>
                                    Offering follow-up scripts for buyers who
                                    gave their contact information
                                </li>
                                <li>
                                    Sending automated emails to anyone who signs
                                    into your open house software
                                </li>
                                <li>
                                    Promoting an agent-branded search app to
                                    attendees
                                </li>
                                <li>
                                    Crafting personalized email templates that
                                    agents can send to gauge interest after the
                                    event
                                </li>
                            </ul>

                            <p className="border-above">
                                Not sure where to start? Share this six-week
                                outreach plan with your agents.
                            </p>

                            <CaseStudy
                                icon={HouseBookSVG}
                                link="https://smartspot.homespotter.com/copy-and-paste-templates-open-house-lead-nurture?utm_source=pillar-page"
                                title="How to Successfully Nurture and Qualify Open House Leads"
                                buttonText="Learn How"
                                bgImage={data.ohLeads}
                            />
                        </div>
                    </div>
                </div>

                {/*Chapter 5*/}
                <div className="openhouseblitz__chapter" id="chapter-5">
                    <div className="openhouseblitz__content-wrap">
                        <div className="content-container">
                            <h2>Chapter 5</h2>
                            <h3>How to set goals and measure your ROI</h3>
                            <GatsbyImage
                                image={
                                    data.ch5_hero.childImageSharp
                                        .gatsbyImageData
                                }
                                className="hero-illustration"
                            />
                            <p>
                                Often, when we talk about goals and ROI, we
                                focus on the numbers. And as a company that
                                works with a lot of data, we can attest: The
                                numbers are important — and they don’t lie.
                            </p>

                            <p>
                                But after speaking with some of our clients, we
                                found that they tend to measure success by both
                                quantitative metrics and qualitative outcomes.
                                Here, we’ll show you how to do the same.
                            </p>

                            <h4>
                                How do I set challenging, but meaningful open
                                house goals for my brand?
                            </h4>

                            <p>
                                Start by looking at the numbers and information
                                you do have, or work to collect the basics
                            </p>

                            <ul className="content-bullets">
                                <li>
                                    The number of open houses your agents host
                                    on an average weekend
                                </li>
                                <li>
                                    The number of directional signs agents use
                                    to promote their opens
                                </li>
                                <li>
                                    The estimated number of attendees agents
                                    expect at typical opens
                                </li>
                                <li>
                                    The outreach agents tend to perform, before
                                    or after their open houses
                                </li>
                                <li>
                                    The type of marketing that agents typically
                                    run before each event
                                </li>
                                <li>
                                    How agents collect contact information for
                                    attendees. Do they work with a digital open
                                    house software, or use paper sign-in sheets?
                                </li>
                            </ul>

                            <p>
                                From there, work with your internal committee to
                                create measurable goals that will help your
                                agents draw in more attendance and make
                                meaningful contact. Consider, too, goals that
                                will help the brand more broadly.
                            </p>

                            <p className="extra-attention">
                                Here are some example goals to consider
                            </p>

                            <ul className="content-bullets">
                                <li>
                                    Increase open house participation by 20% on
                                    non-blitz weekends, and 50% on blitz
                                    weekends
                                </li>
                                <li>
                                    Replace sign-in sheets. Roll out an open
                                    house software that collects buyer contact
                                    information, syncs to CRM and has capability
                                    to send follow-ups
                                </li>
                                <li>
                                    Increase use of agent directional signage by
                                    50%
                                </li>
                                <li>
                                    Optimize your data collection by asking
                                    attendees if they are affiliated with an
                                    agent, approved for a mortgage — and how
                                    they heard about the event
                                </li>
                                <li>
                                    Develop automated email campaigns to help
                                    agents connect with open house attendees
                                </li>
                            </ul>

                            <div className="openhouseblitz__pull-quote">
                                <p>
                                    We stack rank our offices. Every office has
                                    a goal for the number of MEGA open houses
                                    for each event and we consistently track and
                                    report how they're performing against their
                                    goals. Managers love to compete and this
                                    type of reporting motivates them.
                                </p>
                                <div className="quote-author">Dan Forsman</div>
                                <div className="quote-company">
                                    President & CEO - Berkshire Hathaway
                                    HomeServices Georgia Properties
                                </div>
                            </div>

                            <div className="openhouseblitz__pull-quote">
                                <p>
                                    We weren't getting above 130 open houses on
                                    any weekend last October. On our mega open
                                    house weekend this October, we had like 490
                                    open houses. This was the year that we found
                                    what works for our agents and their sellers.
                                </p>
                                <div className="quote-author">
                                    Katie Redican
                                </div>
                                <div className="quote-company">
                                    Vice President of Operations - Berkshire
                                    Hathaway HomeServices New England
                                </div>
                            </div>

                            <div className="bordered">
                                <h5>Measurement</h5>

                                <h4>
                                    What counts as success for a mega open house
                                    blitz?
                                </h4>

                                <p>
                                    Recognize that your immediate data sources
                                    can provide black-and-white insights, but
                                    you should also talk with agents, office
                                    managers/brokers and administrative staff to
                                    glean qualitative successes. Keep in mind
                                    that by performing regular open house
                                    blitzes, you may see even more meaningful
                                    returns in the long term.
                                </p>
                            </div>

                            <h4>In the days after the blitz</h4>

                            <div className="two-cols">
                                <div>
                                    <div className="list-hdr">
                                        Did the brand see an increase in:
                                    </div>
                                    <ul>
                                        <li>Open house participation</li>
                                        <li>Foot traffic</li>
                                        <li>Leads generated for agents</li>
                                        <li>
                                            Leads generated for lenders or
                                            affiliate partners
                                        </li>
                                        <li>
                                            Automated or personal follow-ups to
                                            attendees
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <div className="list-hdr">
                                        Ask staff and agents if they noticed:
                                    </div>
                                    <ul>
                                        <li>
                                            A boost in teamwork and camaraderie
                                            across the office
                                        </li>
                                        <li>
                                            Increased participation or
                                            attendance at sales meetings
                                        </li>
                                        <li>
                                            Agents sharing specific success
                                            stories
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="openhouseblitz__pull-quote">
                                <p>
                                    In 2019, we launched new promotional
                                    materials and automatic follow-ups. We’re
                                    seeing an increase in our pendings, and our
                                    double-sided transactions are up, too. I
                                    mean, is it just from open houses? Maybe
                                    not. But we think that the extra exposure —
                                    all the signs and the increase in buyer
                                    attendance — it's all adding up.
                                </p>
                                <div className="quote-author">
                                    Katie Redican
                                </div>
                                <div className="quote-company">
                                    Vice President of Operations - Berkshire
                                    Hathaway HomeServices New England
                                </div>
                            </div>

                            <h4>Reward participating agents</h4>

                            <p>
                                Take time to celebrate your success, and the
                                agents who worked to make it happen!
                            </p>

                            <p className="extra-attention">
                                Consider rewards for top-performing agents,
                                including those who:
                            </p>

                            <ul className="content-bullets">
                                <li>Got the highest attendance at an open</li>
                                <li>
                                    Reported a personal follow-up with a
                                    promising lead
                                </li>
                                <li>
                                    Received an offer during or after their open
                                </li>
                                <li>
                                    Participate in every open house blitz for
                                    the entire year
                                </li>
                            </ul>

                            <p>
                                If you find that these mega open house blitzes
                                are really moving the needle for your business,
                                you can even consider their weight in
                                determining commission splits or internal
                                designations (like reaching the “President’s
                                Circle”).{' '}
                            </p>

                            <h4>
                                Build brand credibility while promoting your
                                success
                            </h4>

                            <p className="extra-attention">
                                Reinforce your reach, including
                            </p>

                            <ul className="content-bullets">
                                <li>Total number of listings held open</li>
                                <li>
                                    Total number of attendees across all open
                                </li>
                                <li>Ad clicks and views</li>
                                <li>Offers (if applicable)</li>
                                <li>Total reach (across print and digital)</li>
                            </ul>

                            <GatsbyImage
                                image={
                                    data.instagram_post.childImageSharp
                                        .gatsbyImageData
                                }
                            />

                            <h4>Use your results to plan for the future</h4>

                            <p>
                                Take time to celebrate your successes, but don’t
                                forget to use these insights to drive future
                                business initiatives.
                            </p>

                            <p>
                                If an office using an open house software seems
                                to be performing above all the others, consider
                                implementing the technology across all your
                                sales offices. If you notice that a
                                neighborhood’s opens and attendance have
                                increased three-fold in the last year, consider
                                recruitment campaigns or even adding a new
                                office in the area. Consider using the contact
                                information you collected to run targeted ad
                                campaigns directed at in-market, active buyers.
                                When you have robust business data, your
                                optimization opportunities are truly endless.
                            </p>

                            <p>
                                See the results! Download this Case Study and
                                learn how four top brands achieved success with
                                their Mega Open House Events.
                            </p>

                            <CaseStudy
                                title={
                                    'Case Study: How Four Top Brands Leverage Digital Ads to Run Massively Successful Mega Open House Events'
                                }
                                link={
                                    'https://smartspot.homespotter.com/case-study-four-brands-digital-ads-for-openhouse-blitzes?utm_source=pillar-page'
                                }
                                buttonText={'Get the Case Study'}
                                icon={CaseStudySVG}
                                bgImage={data.cta}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

const OpenHouseBlitzRender = props => (
    <StaticQuery
        query={graphql`
            {
                edinaopenhouse: file(
                    relativePath: { eq: "openhouseblitz/edina-open-house.jpg" }
                ) {
                    childImageSharp {
                        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                    }
                }
                cta: file(
                    relativePath: { eq: "case-study/blitz-case-study-bg.jpg" }
                ) {
                    childImageSharp {
                        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                    }
                }
                ohLeads: file(
                    relativePath: { eq: "openhouseblitz/hero-cta-oh-leads.png" }
                ) {
                    childImageSharp {
                        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                    }
                }
                ctaChecklist: file(
                    relativePath: {
                        eq: "openhouseblitz/hero-cta-checklist.png"
                    }
                ) {
                    childImageSharp {
                        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                    }
                }
                hero: file(relativePath: { eq: "openhouseblitz/hero.png" }) {
                    childImageSharp {
                        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                    }
                }
                ch1_hero: file(
                    relativePath: { eq: "openhouseblitz/ch1-hero.png" }
                ) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 493
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
                ch2_hero: file(
                    relativePath: { eq: "openhouseblitz/ch2-hero.png" }
                ) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 493
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
                ch3_hero: file(
                    relativePath: { eq: "openhouseblitz/ch3-hero.png" }
                ) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 493
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
                ch4_hero: file(
                    relativePath: { eq: "openhouseblitz/ch4-hero.png" }
                ) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 480
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
                ch5_hero: file(
                    relativePath: { eq: "openhouseblitz/ch5-hero.png" }
                ) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 447
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
                ftb_open_house: file(
                    relativePath: { eq: "openhouseblitz/ftb-open-house.png" }
                ) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 302
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
                fourGroupsOpenHouses: file(
                    relativePath: {
                        eq: "openhouseblitz/four-groups-agents-open-houses.jpg"
                    }
                ) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 302
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
                ftb_manual_ads: file(
                    relativePath: { eq: "openhouseblitz/ftb-manual-ads.png" }
                ) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 270
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
                reece_open_house_celebration: file(
                    relativePath: {
                        eq: "openhouseblitz/reece-open-house-celebration.png"
                    }
                ) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 780
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
                mega_alexa: file(
                    relativePath: { eq: "openhouseblitz/mega-alexa.png" }
                ) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 320
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
                instagram_post: file(
                    relativePath: { eq: "openhouseblitz/instagram-post.png" }
                ) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 780
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
            }
        `}
        render={data => <OpenHouseBlitz data={data} {...props} />}
    />
)

export default OpenHouseBlitzRender
