import React from 'react'
import '../styles/global.scss'
import { GatsbyImage } from 'gatsby-plugin-image'

export default function FromTheBlog({
    title,
    img,
    link,
    label = 'From the Blog',
    block = 'from-the-blog',
    modifier = 'default',
}) {
    return (
        <a
            target="_blank"
            rel="noreferrer"
            className={`${block} ${block}--${modifier}`}
            href={link}
        >
            <div className={`${block}__image`}>
                <GatsbyImage image={img.childImageSharp.gatsbyImageData} />
            </div>

            <div className={`${block}__text`}>
                <div className={`${block}__label`}>{label}</div>
                <div className={`${block}__title`}>{title}</div>
                <span className={`${block}__link`}>Read More</span>
            </div>
        </a>
    )
}
