import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
export default function ImgBox({action, theme, content, imgSrc, block = "img-box", modifier = "default"}) {
    return (
        <div className={`${block} ${block}--${modifier}` }>
            <GatsbyImage image={imgSrc} className={`${block}__img`} />
        </div>
    );
}

